import React from 'react';
import { string } from 'prop-types';
import classnames from 'classnames';

const ICON_ID = 'search';
const namespace = 'ui-pdp-icon';

const IconSearch = ({ className }) => (
  <svg
    className={classnames(className, namespace)}
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
  >
    <use href={`#${ICON_ID}`} />
  </svg>
);

IconSearch.propTypes = {
  className: string,
};

IconSearch.defaultProps = {
  className: null,
};

IconSearch.ICON_ID = ICON_ID;

export default React.memo(IconSearch);
export { IconSearch };
